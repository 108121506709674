.big-photo {
	padding: 0 3px 2px 3px;
	cursor: pointer;
}

.sidebar-list {
	&.fa-ul {
		margin-left: 1.5em;	
	}

	li {
		padding-left: 5px;
		padding-bottom: 5px;
	}
	a {
		color: black;
	}
}